(function ($) {

  Drupal.behaviors.mobileGnav = {
    vars: {
      mGnav: $('.navigation')
    },
    
    get: function(key) {
      return this.vars[key];
    },

    set: function(key, val) {
      this.vars[key] = val;
    },
    
    init: function () {
      $('.btn_navigation').on('click', function() {
        $(".navigation_container").animate({
          width: '65%'
        }, { duration: 250, queue: false });
        $(".main_container").animate({
          width: '35%'
        }, { duration: 250, queue: false });  
        $(".navigation_container ul.menu li").css({'opacity':'0'});
        setTimeout(function (){
          $(".navigation_container ul.menu li").css({'opacity':'1'});
        }, 100);
        //var navContainerHeight = $(".navigation_container").height();
        //$(".main_container").css({'max-height': + navContainerHeight + 'px'});
        $(".main_container").css({'height': 'auto'});
        var mainContainerHeight = $(".main_container").height();
        $(".navigation_container").css({'height': + mainContainerHeight + 'px'});
        $('#navigation_control').show();
      });

      //Mobile Checkout Back Button
      $('.js-mobile-checkout-backbutton').on('click', function() {
        var page_id = $('body').attr('id');
        if (page_id === 'shipping') {
          window.location.href = '/checkout/samples.tmpl';
        } else if (page_id === 'review') {
          window.location.href = '/checkout/shipping.tmpl';
        } else if (page_id === 'confirm') {
          window.location.href = '/';
        } else {
          window.history.back();
        }
      });

      $('#navigation_control').on('click', function() {
        $(this).hide();
        $(".navigation_container").animate({
          width: '0'
        }, { duration: 250, queue: false });
        $(".main_container").animate({
          width: '100%'
        }, { duration: 250, queue: false });
        $(".main_container").css({'max-height':'100%'});
      });

      // TouchWipe implementation. Open nav on swipe right.
      $(".btn_navigation").touchwipe({
        wipeRight:  function() { 
          $(".navigation_container").animate({
            width: '65%'
          }, { duration: 250, queue: false });
          $(".main_container").animate({
            width: '35%'
          }, { duration: 250, queue: false });
        },
        min_move_x: 20,
        min_move_y: 20,
        preventDefaultEvents: true
      });
    
      // TouchWipe implementation. Close nav on swipe left.
      $("#navigation_control").touchwipe({
        wipeLeft:   function() {
          //alert('swipe left screen.width: ' + screen.width);
          $(this).hide();
          $(".navigation_container").animate({
            width: '0'
          }, { duration: 250, queue: false });
          $(".main_container").animate({
            width: '100%'
          }, { duration: 250, queue: false });
        },
        min_move_x: 20,
        min_move_y: 20,
        preventDefaultEvents: true
      });

      var self = this;
      // Pulling the JQ navigation selector from here
      // I don't know why the bellow var doesn't work
      //var mGnav = self.get('mGnav'); // alert on this returns null
      var mGnav = $('.navigation');
      //alert( 'mGnav.html(): ' + mGnav.html() );

      // Loop through the navigation object
      mGnav.each(function(i) {
        var thisCats = $(this);

        // Every first li item should be this color #D9D9D9
        thisCats.find(".depth-1 > ul.menu > li.first").each(function() {
          $(this).css({'background-color':'#D9D9D9'});
        });
        
        thisCats.find(".depth-2 > ul.menu > li.first").each(function() {
          $(this).css({'background-color':'#D9D9D9'});
        });

        // Main categories
        thisCats.find(".field-mobile-menu > ul.menu > li > a").each(function(i) {
          var menuItemText = $(this).find('span:first').attr('data-link-txt') || $(this).find('span:first').text();
          menuItemText = cleanText(menuItemText);
          $(this).parent().addClass('parent parent-' + menuItemText);
          $(this).next().addClass(menuItemText);
          if ($(this).attr('href').length > 1) {
            // Hide ">" icon on menu items without children
            $(this).find('.icon').hide();
          }
          $(this).on('click', function(e) {
            var a = $(this);
            // Append "?_SUBMIT=signout" to the logout URL            
            var logout = $('li.menu-mlid-b1176212-0608-11e5-bc94-0050569a2719').find('a').attr('href', '/account/signin.tmpl?_SUBMIT=signout');
            // This is to replace the "Back" text string with the clicked menu item text string
            a.parent().find('li.parent-back > a').html(a.text() + '<span class="icon"></span>');
            var thisChildrenClass = $(this).find("span:first").first().attr('class');
            if (thisChildrenClass != 'icon') {
              $(this).parent().siblings().each(function() {
                if ($(this).parent().parent('.menu-container:not(.depth-1)')) {
                  var parentChildrenMenuItemText = splitReplaceTxt($(this).children('div').attr('class'));
                }
              });
              if ($(this).next().hasClass(menuItemText)) {
                $(this).parent().siblings().each(function(i) {
                  var parentChildrenMenuItemText = splitReplaceTxt($(this).children('div').attr('class'));
                  // Show chosen cat submenus
                  $(".menu-container.depth-1." + menuItemText).show();
                  // Hide all that doesn't match chosen cat class
                  $(".menu-container.depth-1").not('.' + menuItemText).hide();
                });
              }
            }
            // Only go back if href have '/' (<front>) URL. If href have any other ('/page.tmpl') URL proceed to that page 
            if ($(this).attr('href').length === 1) {
              e.preventDefault();
              window.scrollTo(-100,0); // first value for left offset, second value for top offset
              $(".navigation").animate({
                left: '-100%',
                scrollTop: $("body").offset().top
              }, { duration: 200 });
            }
          });
        });

        // Sub categories
        thisCats.find(".depth-1 > ul.menu > li > a").each(function(i) {
          var depthOneMenuItemText = $(this).find('span:first').attr('data-link-txt') || $(this).text();
          depthOneMenuItemText = cleanText(depthOneMenuItemText);
          $(this).parent().addClass('parent parent-' + depthOneMenuItemText).removeClass('parent parent-');
          $(this).next().addClass(depthOneMenuItemText);
          if ($(this).attr('href').length > 1) {
            // Hide ">" icon on menu items without children
            $(this).find('.icon').hide();
          }
          $(this).on('click', function(e) {
            $(this).parent().find('li.parent-back > a').html($(this).text() + '<span class="icon"></span>');
            $(this).parent().addClass('parent-' + depthOneMenuItemText);
            if ($(this).next().hasClass(depthOneMenuItemText) && $(this).next().attr('class') != 'undefined') {
              var parentChildrenMenuItemText = splitReplaceTxt($(this).siblings().attr('class'));
              // Show chosen cat submenus
              $(".menu-container.depth-2." + depthOneMenuItemText).show();
              // Hide all that doesn't match chosen cat class
              $(".menu-container.depth-2").not('.' + depthOneMenuItemText).hide();
            }
            // Only go back if href have '/' URL. If href have any '/page.tmpl' URL proceed to that page
            if ($(this).attr('href').length === 1) {
              e.preventDefault();
              // Temp workaround for the "My Account" menu item. We don't have URLs for it yet...
              if ($(this).parent().parent().parent().hasClass('my-account') && $(this).parent().index() > 0) {
                $(".navigation").animate({
                  left: '0',
                  scrollTop: $("body").offset().top
                }, { duration: 200 });
              }
              else if($(this).parent().index() != 0) {
                $(".navigation").animate({
                  left: '-200%',
                  scrollTop: $("body").offset().top
                }, { duration: 200 });
              }
              else {
                $(".navigation").animate({
                  left: '0',
                  scrollTop: $("body").offset().top
                }, { duration: 200 });
              }
            }
          });
        });

        // Sub sub categories
        thisCats.find('.depth-2 > ul.menu > li > a').each(function() {
          var depthOneMenuItemText = $(this).find('span:first').attr('data-link-txt') || $(this).text();
          depthOneMenuItemText = cleanText(depthOneMenuItemText);
          $(this).parent().addClass('parent parent-' + depthOneMenuItemText).removeClass('parent parent-');
          $(this).next().addClass(depthOneMenuItemText);
          if ($(this).attr('href').length > 1) {
            // Hide ">" icon on menu items without children
            $(this).find('.icon').hide();
          }
          $(this).on('click', function(e) {
            // Only go back if href have '/' (<front>) URL. If href have any other ('/page.tmpl') URL proceed to that page
            if ($(this).attr('href').length === 1) {
              e.preventDefault();
              // Back to previous cats if this is '/' or doesn't have any children cat
              if($(this).parent().index() >= 0){
                $(".navigation").animate({
                  left: '-100%',
                  scrollTop: $("body").offset().top
                }, { duration: 200 });
              }
            }
          });  
        }); 

        // Append default user picture to the my account category
        thisCats.find('.parent-my-account > a').prepend('<span class="user-pic"></span>');        
        //thisCats.find('.parent-my-account > a .icon').show();

        // Append checkout icon to the "Checkout" category
        thisCats.find(".parent-checkout a").prepend('<span class="checkout-icon gnav"></span>');
      });

      // Append default user picture to the my account category
      //$(".my-account ~ span").html('<span class="user-pic"></span>');

      // Append checkout icon to the "Checkout" category
      //$(".parent-checkout a").prepend('<span class="checkout-icon"></span>');

      // Append welcome message to the logged-in user
      $(".logged-in .my-account ul li.first").after('<li class="menu-mlid-051660ec-3c0d-11e3-814d-000c29cc69ea parent parent-welcome"><span class="welcome-user"><span class="icon"><span class="user-pic"></span></span><span class="welcome"></span><span class="welcome-name"></span></span></li>');
      // We need to pull the Perlgem (PG) user name data from somewhere.
      // In this instance, we can add a hidden div, like this:
      // <div class="mobile-logged-in-user"> 
      // with another children div within it, like this:
      // <div class="user-name"> in page.tpl.php 
      // This way the above div will be populated with the PG logged-in user data
      // and we can extract the user name like this:
      //var mobileLoggedInUser = $(".mobile-logged-in-user");
      //$(".logged-in .welcome-name").text(mobileLoggedInUser.find('.user-name').text());

      // For anonymous users
      // We're not using the "Welcome!" message for anonymous (not logged-in) users
      //$(".not-logged-in .my-account ul li.first").after('<li class="menu-mlid-051660ec-3c0d-11e3-814d-000c29cc69ea parent parent-welcome"><span class="welcome-user"><span class="icon"><span class="user-pic"></span></span><span class="welcome"></span><span class="welcome-name"></span></span></li>');
      
      // Footer menu
      $('#footer').find('.footer-bottom ul.menu li').each(function(i) {
        var item = $(this);
        item.attr('data-item', i).addClass('item-' + i);
      });

      // Remove unneeded characters to create CSS classes from anchor text.
      function cleanText(txt) {
        // TODO: optimize with regex...
        txt = txt.replace('\'', '');
        txt = txt.replace(' & ', '-');
        txt = txt.replace('/', '-');
        txt = txt.replace('---', '-');
        txt = txt.replace(/\s/g, '-');
        txt = txt.replace('3', 'three');
        txt = txt.toLowerCase();
        return txt;
      }
      
      // Split replace text
      function splitReplaceTxt(txt) {
        txt = txt.split(' ')[txt.split(' ').length - 1];
        txt = txt.replace('3', 'three');
        return txt;
      }
    },
    
    attach: function (context, settings) {
      this.init();
    }
  };

/**
 * Mobile-specific 'quickview' action for product cta buttons
 */
// Drupal.behaviors.ctaButton.actions.quickview = function(event, element) {
//   event.preventDefault();
//   // code goes here
//   return false;
// };

Drupal.behaviors.startBetterMobileJS = {
  attach: function(context, settings) {
    $('.start-better-nav').parents('.module').css('border-bottom', 'none');
    $('.start-better-nav li').on('click', function(e) {
      e.preventDefault();
      var ele = $(this).find('a');
      var selectedEle = ele.attr('nav-type');
      $('.start-better-content').parents('.module').hide();
      $('.' + selectedEle).parents('.module').show();
    });
    $('.start-better-nav li a').first().trigger('click');
    var sb_accorion_flex_arr = [];
    $('.start-better-content.accordionItem').on('click', function(e) {
      e.preventDefault();
      var sb_this = $(this);
      var sb_flex_id = sb_this.find('.flexslider').attr('id');
      if (sb_accorion_flex_arr.indexOf(sb_flex_id) == -1) {
        $('#' + sb_flex_id).data('flexslider').setup();
        sb_accorion_flex_arr.push(sb_flex_id);
      }
    });
  }
}

})(jQuery);
